import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import { useRouteChange } from '../util/hooks';
import { GTMPageView } from '../util/googleTagManager';

import '../styles/index.scss';
import Modal from 'react-modal';

/* If in development mode, inject axe accessibility tools. */
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    const ReactDOM = require('react-dom');
    const axe = require('@axe-core/react');

    axe(React, ReactDOM, 1000, {
        runOnly: ['best-practice', 'wcag2a', 'wcag2aa']
    });
}

/* Inject Sentry reporting. */
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = [];

    /*
     * If we're building a production bundle with Node, then rewrite the
     * project root as relative to 'app:///' so that source maps will work.
     */
    if (
        process.env.NEXT_IS_SERVER === 'true' &&
        process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
        integrations.push(
            new RewriteFrames({
                iteratee: (frame) => {
                    frame.filename = frame.filename.replace(
                        process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
                        'app:///'
                    );

                    frame.filename = frame.filename.replace('.next', '_next');
                    return frame;
                }
            })
        );
    }

    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations
    });
}

function App({ Component, pageProps, err }) {
    // const popupStyles = {
    //     content: {
    //         top: '50%',
    //         left: '50%',
    //         right: 'auto',
    //         bottom: 'auto',
    //         marginRight: '-50%',
    //         transform:
    //             'translate(-50%, -50%)',
    //         textAlign: 'center',
    //         width: '50%',
    //         // height: '30vh',
    //         maxWidth: '600px',
    //         borderRadius: '15px',
    //         padding: '10px',
    //         zIndex: '1'
    //     }
    // };

    /* Trigger a page view on in-app routing */
    // const [modalIsOpen, setIsOpen] = React.useState(true);
    //
    // const { options } = pageProps;
    //
    // let welcomePopUpContent;
    // let welcomePopUpHeading;
    //
    // if (options) {
    //     Modal.setAppElement('main');
    //     welcomePopUpContent = options.acf_site_options.welcomePopUpContent;
    //     welcomePopUpHeading = options.acf_site_options.welcomePopUpHeading;
    // }
    //
    // function openModal() {
    //     setIsOpen(true);
    // }
    //
    // function closeModal() {
    //     setIsOpen(false);
    // }


    useRouteChange(GTMPageView);

    return (
        <>
            {/*{options && (*/}
            {/*    <Modal*/}
            {/*        isOpen={modalIsOpen}*/}
            {/*        onRequestClose={closeModal}*/}
            {/*        contentLabel={welcomePopUpHeading}*/}
            {/*        style={popupStyles}*/}
            {/*    >*/}
            {/*        <div className="border-primary-700 border-10 rounded-15 pt-6 md:px-6 h-[30vh]">*/}
            {/*            <h1 className="uppercase font-bold text-16 md:text-20">*/}
            {/*                {welcomePopUpHeading}*/}
            {/*            </h1>*/}
            {/*            <div*/}
            {/*                className="font-semibold text-12 md:text-16"*/}
            {/*                dangerouslySetInnerHTML={{*/}
            {/*                    __html: welcomePopUpContent*/}
            {/*                }}*/}
            {/*            />*/}
            {/*            <div className="inline-block text-center">*/}
            {/*                <button*/}
            {/*                    onClick={closeModal}*/}
            {/*                    className="flex items-center justify-center md:py-1 font-semibold text-white uppercase transition my-4 md:px-6 bg-primary md:text-20 text-14 rounded-30 focus:outline-none"*/}
            {/*                >*/}
            {/*                    <p className="m-0 pl-4">Enter</p>*/}
            {/*                    <img*/}
            {/*                        src="/images/right-arrow.png"*/}
            {/*                        alt="enter"*/}
            {/*                        className="md:py-2 px-2 inline"*/}
            {/*                    />*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </Modal>*/}
            {/*)}*/}
            <Component {...pageProps} err={err} />
        </>
    );
}

export default App;
